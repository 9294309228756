import React from 'react'
import 'twin.macro';

export default function Services() {
    return (
        <div tw="relative bg-white py-2 w-full">
            <div tw="mx-auto px-4 text-center sm:px-6 lg:px-8  w-11/12">
                <h2 tw="text-base font-semibold tracking-wider text-secondary-900 uppercase"></h2>
                <p tw="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                GETTING STARTED
                </p>
                <p tw="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                We’re a full-service marketing firm with a passion for a radical open mind and radical transparency. No surprises, no gimmicks, just the bottom line.
                </p>
                    <div tw="mt-12">
                        <div tw="flex flex-col md:flex-row justify-center items-center">
                                <div tw="mx-4 pt-6">
                                    <div tw="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                    <div tw="-mt-6">
                                    <div>
                                        <span tw="inline-flex items-center justify-center p-3 bg-secondary-900 rounded-md shadow-lg">
                                        <svg tw="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        </span>
                                    </div>
                                    <h3 tw="mt-8 text-lg font-medium text-gray-900 tracking-tight">Design</h3>
                                    <p tw="mt-5 text-base text-gray-500">
                                    Complete digital and print design services. Collaboration and an understanding of your vision so we uniquely display your brand.
                                    </p>
                                    </div>
                                </div>
                                </div>

                                <div tw="mx-4 pt-6">
                                <div tw="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                    <div tw="-mt-6">
                                    <div>
                                        <span tw="inline-flex items-center justify-center p-3 bg-secondary-900 rounded-md shadow-lg">
                                        <svg tw="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                        </svg>
                                        </span>
                                    </div>
                                    <h3 tw="mt-8 text-lg font-medium text-gray-900 tracking-tight">Develop</h3>
                                    <p tw="mt-5 text-base text-gray-500">
                                    No project too big or small! We dig deep and research each project to develop a fresh plan of action. Up front services, never any surprises.
                                    </p>
                                    </div>
                                </div>
                                </div>

                                <div tw="mx-4 pt-6">
                        <div tw="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                            <div tw="-mt-6">
                            <div>
                                <span tw="inline-flex items-center justify-center p-3 bg-secondary-900 rounded-md shadow-lg">
                                <svg tw="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                                </span>
                            </div>
                            <h3 tw="mt-8 text-lg font-medium text-gray-900 tracking-tight">Success</h3>
                            <p tw="mt-5 text-base text-gray-500">
                            We succeed when you do. We pride ourselves on word of mouth business and the satisfaction of our clients is our number one priority.
                            </p>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}
